import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import SimpleDialog from '../common/SimpleDialog'
import BreakdownForm, {BreakdownFields} from '../forms/BreakdownForm'

interface Props extends RouteComponentProps {
  breakdown: Breakdown
  names: Types[]
  projects: itemType[]
  loadData: () => void
  locations: Attribute[]
}

function BreakdownTableRow(props: Props) {
  const [opens, setOpens] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
    setSubmitted(false)
    setSubmitting(false)
  };

  const onSubmit = async (values: BreakdownFields) => {
    setSubmitting(true)
    var getResource = await fetch(`https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources?name=${values.Name}`, {method: 'GET'})
    var resource = await getResource.json()
    if (!resource) return
    var resourceD = resource.resources.Item
    if (!resourceD) return
    var getRR = await fetch(`https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/recoverability?role=${resourceD.Role}`, {method: 'GET'})
    var RR = await getRR.json()
    if (!RR) return
    var RRd : Recoverability = RR.recoverabilities.Item
    if (!RRd) return
    var tStatus: string = ''
    var current = values.Current!
    if (current > RRd.Good) {
      tStatus = 'High'
    }
    if (RRd.Good >= current && current >= RRd.Low) {
      tStatus = 'Expected'
    }
    else if (current < RRd.Low) {
      tStatus = 'Low'
    }
    var cL: string = values.CurrentLocation
    if(!values.CurrentLocation) cL = resourceD.Location
    var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/breakdowns/submit', {
      body: JSON.stringify({
        Name: values.Name,
        Current: current,
        Projects: values.Projects,
        StatusDate: values.StatusDate,
        Notes: values.Notes,
        Level: resourceD.Level,
        CurrentLocation: cL,
        Role: resourceD.Role,
        Started: resourceD.Location,
        Low: RRd.Low,
        Good: RRd.Good,
        TargetStatus: tStatus
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    props.loadData()
  }

var dateNow = moment().unix()
var future = moment(props.breakdown.StatusDate).add(30, 'd')
var futureCheck: boolean = moment(dateNow).isSameOrAfter(future)
var pA = props.breakdown.Projects
var projects = pA.toString()
  return (
    <React.Fragment>
      <TableRow  hover style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <TableCell align='center'>{props.breakdown.Name}</TableCell>
        <TableCell align='center'>{props.breakdown.Level}</TableCell>
        <TableCell align='center'>{props.breakdown.CurrentLocation}</TableCell>
        <TableCell align='center'>{props.breakdown.Role}</TableCell>
        <TableCell align='center'>{props.breakdown.Started}</TableCell>
        <TableCell align='center'>{props.breakdown.Low}</TableCell>
        <TableCell align='center'>{props.breakdown.Good}</TableCell>
        <TableCell align='center'>{props.breakdown.Current}</TableCell>
        <TableCell align='center'>{props.breakdown.TargetStatus}</TableCell>
        <TableCell align='center'>{projects}</TableCell>
        <TableCell align='center' style={futureCheck ? {backgroundColor: '#A30B1A'} : undefined}>{moment.unix(props.breakdown.StatusDate).format('DD/MM/YYYY')}</TableCell>
        <TableCell align='center'>{props.breakdown.Notes}</TableCell>

      </TableRow>
      <SimpleDialog open={opens} handleClose={handleClose} title='Add new Breakdown'>
        <BreakdownForm 
          values={props.breakdown} 
          submitted={submitted} 
          submitting={submitting} 
          isDisabled={isDisabled} 
          onSubmit={onSubmit} 
          names={props.names} 
          projects={props.projects}
          locations={props.locations} />
      </SimpleDialog>
    </React.Fragment>
  )
}

export default withRouter(BreakdownTableRow)