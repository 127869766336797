import React from 'react'
import PaperSheet from '../components/common/Paper'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import ResourceTable from '../components/tables/ResourceTable'
import ResourceForm, { ResourceFields } from '../components/forms/ResourceForm'
import AddButton from '../components/common/buttons/AddButton'
import SimpleDialog from '../components/common/SimpleDialog'
import Snackbar from '../components/common/Snackbar'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import { AttributeFilter } from '../components/utils/AttributeFilter'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CustomButton from '../components/common/buttons/CustomButton'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    formControl: {
      width: '100%',
    }
  })
)


export default function ResourceConfig() {
  const classes = useStyles();
  const [resource, setResource] = React.useState<Resource[]>([]);
  const [rData, setRData] = React.useState<Resource[]>([]);
  const [levels, setLevels] = React.useState<Attribute[]>([])
  const [roles, setRoles] = React.useState<Attribute[]>([])
  const [locations, setLocations] = React.useState<Attribute[]>([])
  const [types, setTypes] = React.useState<Attribute[]>([])
  const [managers, setManagers] = React.useState<Attribute[]>([])
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)
  const [filterLevel, setFLevel] = React.useState<string>("")
  const [filterRole, setFRole] = React.useState<string>("")
  const [filterLocation, setFLocation] = React.useState<string>("")
  const [filterOrg, setFOrg] = React.useState<string>("")

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitting(false)
    setSubmitted(false)
  }

  const doStartedCheck = async () => {
    var update: number = 0
    console.log(resource.length)
    for(var f = 0; f < resource.length; f++) {
      if (resource[f].Role === "JT 1 Dev") {
        console.log(resource[f])
        var future = moment(resource[f].Started).add(99, 'd').unix()
        var dateNow = moment().unix()
        var futureCheck: boolean = dateNow > future
        console.log(futureCheck)
        if(futureCheck) {
          update = update + 1
          try {
            var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources/submit', {
            body: JSON.stringify({
              Name: resource[f].Name,
              Level: resource[f].Level,
              OrgStructure: resource[f].OrgStructure,
              Role: "JT 2 Dev",
              Location: resource[f].Location,
              Started: resource[f].Started,
              Manager: resource[f].Manager,
              Type: resource[f].Type
            }), method: 'POST'
          })
          } catch (error) {
            setError(error)
          }
        }
      }
    }
    if(update === 0) setError("No resources to update")
    var aresponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources', {method: 'GET'})
    var aresource = await aresponse.json()
    setResource(aresource.resources.Items)
    setRData(aresource.resources.Items)
    setError('Updated ' + update + ' resources')
  }

  const loadResources = async() => {
    var bresponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources', {method: 'GET'})
    var bresource = await bresponse.json()
    setResource(bresource.resources.Items)
    setRData(bresource.resources.Items)
  }

  const doFilter = (data: string, attribute: string) => {
    if (attribute === 'Role') setFRole(data)
    if (attribute === 'Location') setFLocation(data)
    if (attribute === 'Level') setFLevel(data)
    if (attribute === 'Org') setFOrg(data)
    var a: Filter= {value: data, attribute: attribute}
    console.log(a)
    setRData(Filter(resource, a))
  }

  const clear = () => {
    setRData(resource)
    setFRole("")
    setFLocation("")
    setFLevel("")
    setFOrg("")
  }

  React.useEffect(() => {
    setLoading(true)
    async function getData() {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources', {method: 'GET'})
      var resource = await response.json()
      setResource(resource.resources.Items)
      setRData(resource.resources.Items)
      var attributeResponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/attributes', {method: 'GET'})
      var aR = await attributeResponse.json()
      aR = aR.attributes.Items
      setLevels(AttributeFilter(aR,'Level'))
      setRoles(AttributeFilter(aR,'Role'))
      setLocations(AttributeFilter(aR,'Location'))
      setTypes(AttributeFilter(aR,'Resource Type'))
      setManagers(AttributeFilter(aR,'Manager'))
    }
    getData()
    setLoading(false)
  }, [])

  const onSubmit = async (values: ResourceFields) => {
    setSubmitting(true)
    try {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources/submit', {
      body: JSON.stringify({
        ...values
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    loadResources()
    setFRole("")
    setFLocation("")
    setFLevel("")
    setFOrg("")
    } catch (error) {
      setSubmitted(false)
      setSubmitting(false)
    }
  }

  console.log(rData)

  return (
    <PaperSheet title='Manage Resources' divider rightSideContent={
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AddButton  onClick={handleOpen} title='Add New Resource'/>
        </Grid>
      </Grid>}>
      {!loading && hasPermissions([Permissions.SUPER_CONFIGURATION]) && 
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="filter-panel-content"
              id="filter-panel-header"
            >
              <Typography className={classes.heading}>Actions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterLevel}
                      onChange={(e) => doFilter((e.target.value as string), 'Level')}
                    >
                      {levels?.map(d => (
                        <MenuItem key={levels?.indexOf(d)} value={d.Name}>
                          {d.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterRole}
                      onChange={(e) => doFilter((e.target.value as string), 'Role')}
                    >
                      {roles?.map(d => (
                        <MenuItem key={roles?.indexOf(d)} value={d.Name}>
                          {d.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Location</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterLocation}
                      onChange={(e) => doFilter((e.target.value as string), 'Location')}
                    >
                      {locations?.map(d => (
                        <MenuItem key={levels?.indexOf(d)} value={d.Name}>
                          {d.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Org Structure</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterOrg}
                      onChange={(e) => doFilter((e.target.value as string), 'Org')}
                    >
                      {roles?.map(d => (
                        <MenuItem key={levels?.indexOf(d)} value={d.Name}>
                          {d.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <CustomButton title='Clear Filter' onClick={clear}/>
                <div style={{marginLeft: 10}}>
                  <CustomButton title='Update Resources' onClick={doStartedCheck}/>
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <ResourceTable 
            resources={rData}
            levels={levels} 
            roles={roles} 
            locations={locations} 
            types={types}
            managers={managers}
            loadData={loadResources}/>
        </Grid>
        </Grid>}
      <SimpleDialog open={open} handleClose={handleClose} title='Manage Resource'>
        <ResourceForm 
          submitted={submitted} 
          submitting={submitting} 
          isDisabled={isDisabled} 
          onSubmit={onSubmit} 
          levels={levels} 
          roles={roles} 
          locations={locations}
          managers={managers} 
          types={types}/>
      </SimpleDialog>
      {error && <Snackbar message={error}/>}
    </PaperSheet>
  )
}

export function Filter(data: Resource[], filter: Filter) {
  var array : Resource[] = data
  var filtered: Resource[] = []
  let propertyToUtilise: keyof Resource = filter.attribute as keyof Resource
  if (filter.attribute === 'Org') {
      propertyToUtilise = 'OrgStructure';
  }
  for (let i = 0; i < array.length; i++) {
      if (array[i][propertyToUtilise] === filter.value) {
          filtered.push(array[i]);
      }
  }
  return filtered
}