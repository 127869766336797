import React, { Component } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import SimpleDialog from '../common/SimpleDialog'
import ResourceForm, {ResourceFields} from '../forms/ResourceForm'

interface Props extends RouteComponentProps {
  resource: Resource
  levels: Attribute[]
  roles: Attribute[]
  locations: Attribute[]
  types: Attribute[]
  managers: Attribute[]
  loadData: () => void
}

function ResourceTableRow(props: Props) {
  const [opens, setOpens] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleClose = () => {
    setOpens(false);
    setSubmitted(false)
    setSubmitting(false)
  };

  const onSubmit = async (values: ResourceFields) => {
    setSubmitting(true)
    var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources/submit', {
      body: JSON.stringify({
        ...values
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    props.loadData()
  }


  return (
    <React.Fragment>
      <TableRow  hover style={{ cursor: 'pointer' }} onClick={handleClickOpen}>
        <TableCell align='center'>{props.resource.Name}</TableCell>
        <TableCell align='center'>{props.resource.Level}</TableCell>
        <TableCell align='center'>{props.resource.OrgStructure}</TableCell>
        <TableCell align='center'>{props.resource.Role}</TableCell>
        <TableCell align='center'>{props.resource.Location}</TableCell>
        <TableCell align='center'>{moment.unix(props.resource.Started).format('DD/MM/YYYY')}</TableCell>
        <TableCell align='center'>{props.resource.Manager}</TableCell>
        <TableCell align='center'>{props.resource.Type}</TableCell>
      </TableRow>
      <SimpleDialog open={opens} handleClose={handleClose} title='Add new Resource'>
        <ResourceForm 
          values={props.resource} 
          submitted={submitted} 
          submitting={submitting} 
          isDisabled={isDisabled} 
          onSubmit={onSubmit} 
          levels={props.levels} 
          roles={props.roles} 
          locations={props.locations}
          managers={props.managers}
          types={props.types}/>
      </SimpleDialog>
    </React.Fragment>
  )
}

export default withRouter(ResourceTableRow)
