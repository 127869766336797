import React from 'react'
import PaperSheet from '../components/common/Paper'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import RecoverabilityTable from '../components/tables/RecoverabilityTable'
import RecoverabilityForm, { RecoverabilityFields } from '../components/forms/RecoverabilityForm'
import AddButton from '../components/common/buttons/AddButton'
import SimpleDialog from '../components/common/SimpleDialog'
import Snackbar from '../components/common/Snackbar'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import { AttributeFilter } from '../components/utils/AttributeFilter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    }
  })
)


export default function RecoverabilityConfig() {
  const classes = useStyles();
  const [recoverabilities, setRecoverabilities] = React.useState<Recoverability[]>([]);
  const [levels, setLevels] = React.useState<Attribute[]>([])
  const [roles, setRoles] = React.useState<Attribute[]>([])
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitting(false)
    setSubmitted(false)
  }
  const loadData = async() => {
    var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/recoverability', {method: 'GET'})
    var recoverability = await response.json()
    setRecoverabilities(recoverability.recoverabilities.Items)
  }

  React.useEffect(() => {
    setLoading(true)
    async function getData() {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/recoverability', {method: 'GET'})
      var recoverability = await response.json()
      setRecoverabilities(recoverability.recoverabilities.Items)
      var attributeResponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/attributes', {method: 'GET'})
      var aR = await attributeResponse.json()
      aR = aR.attributes.Items
      setLevels(AttributeFilter(aR,'Level'))
      setRoles(AttributeFilter(aR,'Role'))
    }
    getData()
    setLoading(false)
  }, [])

  const onSubmit = async (values: RecoverabilityFields) => {
    setSubmitting(true)
    try {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/recoverability/submit', {
      body: JSON.stringify({
        ...values
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    loadData()
    } catch (error) {
      setSubmitted(false)
      setSubmitting(false)
    }
  }

  return (
    <PaperSheet title='Manage Recoverability' divider rightSideContent={<AddButton  onClick={handleOpen} title='Add New Recoverability'/>}>
      {!loading && hasPermissions([Permissions.SUPER_CONFIGURATION]) && 
      <RecoverabilityTable 
        recoverabilityArray={recoverabilities}
        levels={levels} 
        roles={roles}
        loadData={loadData}/>}
      <SimpleDialog open={open} handleClose={handleClose} title='Manage Recoverability'>
        <RecoverabilityForm 
          submitted={submitted} 
          submitting={submitting} 
          isDisabled={isDisabled} 
          onSubmit={onSubmit} 
          levels={levels} 
          roles={roles}/>
      </SimpleDialog>
      {error && <Snackbar message={error}/>}
    </PaperSheet>
  )
}