import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import React, { Component } from 'react'
import SessionContext, { DarkModeKey, LocalTokenKey } from '../../SessionContext'
interface State {
  token: TokenResponse | null,
  hasAttemptedLocalToken: boolean
  expired: boolean
  isLoggedIn: boolean
  helpActive: boolean
  darkMode: boolean
}

/**
 * This component is almost near the root, and will handle recovering existing
 * sessions and providing the sessionContext to components below it.
 */
export default class SessionProvider extends Component<{user: any}, State> {
  constructor (props: {user: any}) {
    super(props)
    this.state = {
      hasAttemptedLocalToken: false,
      token: null,
      expired: false,
      isLoggedIn: false,
      helpActive: false,
      darkMode: false
    }
  }

  async componentDidMount () {
    var user = this.props.user
    var localTokenItem = user.signInUserSession.idToken.payload
    if (!localTokenItem) return this.setState({ hasAttemptedLocalToken: true }) // No token?
    var localToken: TokenResponse = localTokenItem
    if (Date.now() / 1000 > parseInt(localToken.exp)) return await Auth.signOut() // An old and expired token?
    this.changeTokenObject(localToken) // Must be a good token!
    if (localStorage.getItem(DarkModeKey) === 'on') this.setState({ darkMode: true })
  }

  changeTokenObject = (token: TokenResponse) => {
    this.setState({ token, hasAttemptedLocalToken: true, expired: false, isLoggedIn: true })
    localStorage.setItem(LocalTokenKey, JSON.stringify(token))
  }

  logout = async (noRevoke = false) => {
    if (!noRevoke) await Auth.signOut().catch(console.error)
    this.setState({ token: null })
    localStorage.removeItem(LocalTokenKey)
  }

  setExpired = () => {
    this.setState({ expired: true })
  }
  toggleHelp = () => {
    this.setState({ helpActive: !this.state.helpActive })
  }
  toggleDarkMode = () => {
    var darkMode = !this.state.darkMode
    this.setState({ darkMode })
    localStorage.setItem(DarkModeKey, darkMode ? 'on' : 'off')
  }

  render () {
    if (!this.state.hasAttemptedLocalToken) return null
    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#A30B1A'
        },
        secondary: {
          main: '#2196f3',
          contrastText: '#000'
        },
        type: this.state.darkMode ? 'dark' : 'light'
      },
      typography: {
        fontFamily: '"fujitsu"'
      }
    })
    var isLoggedIn = Object.keys(this.state.token || {}).length > 1
    var uObj = this.props.user
    var Obj : any = uObj.signInUserSession.idToken.payload
    var sessionContext = {
      token: this.state.token,
      changeTokenObject: this.changeTokenObject,
      logout: this.logout,
      isLoggedIn,
      expired: this.state.expired,
      setExpired: this.setExpired,
      userRole: Obj['cognito:groups'],
      darkMode: this.state.darkMode,
      helpActive: this.state.helpActive,
      toggleHelp: this.toggleHelp,
      toggleDarkMode: this.toggleDarkMode
    }
    window.sessionContext = sessionContext

    return (
      <SessionContext.Provider value={sessionContext}>
        <ThemeProvider theme={theme}>
          {this.props.children}
        </ThemeProvider>
      </SessionContext.Provider>
    )
  }
}
