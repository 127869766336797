import React from 'react'
import PaperSheet from '../components/common/Paper'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import BreakdownTable from '../components/tables/BreakdownTable'
import BreakdownForm, { BreakdownFields } from '../components/forms/BreakdownForm'
import AddButton from '../components/common/buttons/AddButton'
import SimpleDialog from '../components/common/SimpleDialog'
import Snackbar from '../components/common/Snackbar'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import { AttributeFilter } from '../components/utils/AttributeFilter'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CustomButton from '../components/common/buttons/CustomButton'
import * as Excel from 'exceljs'
import moment from 'moment'
import { WrapText } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    formControl: {
      width: '100%',
    }
  })
)


export default function BreakdownConfig() {
  const classes = useStyles();
  const [breakdown, setBreakdown] = React.useState<Breakdown[]>([]);
  const [bData, setBData] = React.useState<Breakdown[]>([]);
  const [levels, setLevels] = React.useState<Attribute[]>([])
  const [roles, setRoles] = React.useState<Attribute[]>([])
  const [locations, setLocations] = React.useState<Attribute[]>([])
  const [tStatusses, setTStatusses] = React.useState<Attribute[]>([])
  const [names, setNames] = React.useState<Types[]>([])
  const [projects, setProjects] = React.useState<itemType[]>([])
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)
  const [filterLevel, setFLevel] = React.useState<string>("")
  const [filterRole, setFRole] = React.useState<string>("")
  const [filterLocation, setFLocation] = React.useState<string>("")
  const [filterTarget, setFTarget] = React.useState<string>("")

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitting(false)
    setSubmitted(false)
  }

  const doFilter = (data: string, attribute: string) => {
    if (attribute === 'Role') setFRole(data)
    if (attribute === 'Location') setFLocation(data)
    if (attribute === 'Level') setFLevel(data)
    if (attribute === 'Target') setFTarget(data)
    var a: Filter= {value: data, attribute: attribute}
    setBData(Filter(breakdown, a))
  }

  const clear = () => {
    setBData(breakdown)
    setFRole("")
    setFLocation("")
    setFLevel("")
    setFTarget("")
  }

  const createExcel = async () => {
    var linkRef: React.RefObject<HTMLAnchorElement> = React.createRef()
    var workbook = new Excel.Workbook()
    var now = moment().unix()
    workbook.creator = 'Byte - Opibus'
    workbook.created = new Date()
    var breakdownSheet = workbook.addWorksheet('Breakdown')
    const headers = ['Name', 'Level', 'Site', 'Role', 'Started', 'Lower', 'Upper', 'Current', 'On Target?', 'Projects', 'StatusDate', 'Notes']
    function createColumns(data: string[]){ 
      var columns: Excel.TableColumnProperties[] = []
      for(var z = 0; z < data.length; z++) {
        var l = {name: data[z], filterButton: true}
        columns.push(l)
      }
      return columns
    }
    function createRows(){
      var rows = []
      for(var z = 0; z < breakdown.length; z++) {
        var l = [breakdown[z].Name,
        breakdown[z].Level, 
        breakdown[z].CurrentLocation, 
        breakdown[z].Role, 
        breakdown[z].Started, 
        breakdown[z].Low + '%', 
        breakdown[z].Good + '%', 
        breakdown[z].Current + '%',
        breakdown[z].TargetStatus,
        breakdown[z].Projects.toString(),
        moment.unix(breakdown[z].StatusDate).format('DD/MM/YYYY'),
        breakdown[z].Notes]
        rows.push(l)
      }
      return rows
    }
    var columns = {name: headers[1], filterButton: true}
    breakdownSheet.addTable({
      name: 'Breakdowns',
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium2',
        showRowStripes: true,
      },
      columns: createColumns(headers),
      rows: createRows(),
    });
    var ws = workbook.getWorksheet('Breakdown')
    ws.getColumn(1).width = 28
    ws.getColumn(1).alignment = {wrapText: true}
    ws.getColumn(ws.actualColumnCount).width = 60
    ws.getColumn(ws.actualColumnCount).alignment = {wrapText: true}
    ws.getColumn(ws.actualColumnCount-1).width = 18
    ws.getColumn(ws.actualColumnCount-1).alignment = {wrapText: true}
    ws.getColumn(ws.actualColumnCount-2).width = 40
    ws.getColumn(ws.actualColumnCount-2).alignment = {wrapText: true}
    ws.protect('Byte1', {})
    var filename = 'Breakdown ' + moment.unix(now).format('DD/MM/YYYY') + ' .xlsx'
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer])

    // Weird, but this is how you trigger a browser to download a blob.
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      var a = window.document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.download = filename
      a.click()
    }
  }

  const loadData = async() => {
    var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/breakdowns', {method: 'GET'})
    var breakdowns = await response.json()
    var aB: Breakdown[] = breakdowns.breakdowns.Items
      aB = aB.sort(function(a, b) {
        var nameA = a.Name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.Name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      })
      setBreakdown(aB)
      setBData(aB)
  }

  React.useEffect(() => {
    setLoading(true)
    async function getData() {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/breakdowns', {method: 'GET'})
      var breakdowns = await response.json()
      var aB: Breakdown[] = breakdowns.breakdowns.Items
      aB = aB.sort(function(a, b) {
        var nameA = a.Name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.Name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      })
      setBreakdown(aB)
      setBData(aB)
      var projectsResponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/projects', {method: 'GET'})
      var p = await projectsResponse.json()
      p = p.projects.Items
      var itemTypeArray: itemType[] = []
      for (var i = 0; i <p.length; i++) {
        var currentD = p[i]
        itemTypeArray.push({label: currentD.Name, value: currentD.Name})
      }
      setProjects(itemTypeArray)
      var resourcesResponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources', {method: 'GET'})
      var r = await resourcesResponse.json()
      var resourceArray: Resource[] = r.resources.Items
      var typesArray: Types[] = []
      for (var i = 0; i <resourceArray.length; i++) {
        var currentData = resourceArray[i]
        typesArray.push({Name: currentData.Name})
      }
      setNames(typesArray)
      var attributeResponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/attributes', {method: 'GET'})
      var aR = await attributeResponse.json()
      aR = aR.attributes.Items
      setLevels(AttributeFilter(aR,'Level'))
      setRoles(AttributeFilter(aR,'Role'))
      setLocations(AttributeFilter(aR,'Location'))
      setTStatusses(AttributeFilter(aR,'Target Status'))
    }
    getData()
    setLoading(false)
  }, [])

  const onSubmit = async (values: BreakdownFields) => {
    setSubmitting(true)
    var getResource = await fetch(`https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/resources?name=${values.Name}`, {method: 'GET'})
    var resource = await getResource.json()
    if (!resource) return
    var resourceD = resource.resources.Item
    if (!resourceD) return
    var getRR = await fetch(`https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/recoverability?role=${resourceD.Role}`, {method: 'GET'})
    var RR = await getRR.json()
    if (!RR) return
    var RRd : Recoverability = RR.recoverabilities.Item
    if (!RRd) return
    var tStatus: string = ''
    var current = values.Current!
    if (values.Projects !== ["Long Term Leave"]) {
      if (current > RRd.Good) {
        tStatus = 'High'
      }
      if (RRd.Good > current && current > RRd.Low) {
        tStatus = 'Expected'
      }
      else if (current < RRd.Low) {
        tStatus = 'Low'
      }
    } else {
      tStatus = 'N/A'
      current = 0
    }
    var cL: string = values.CurrentLocation
    if(!values.CurrentLocation) cL = resourceD.Location
    var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/breakdowns/submit', {
      body: JSON.stringify({
        Name: values.Name,
        Current: current,
        Projects: values.Projects,
        StatusDate: values.StatusDate,
        Notes: values.Notes,
        Level: resourceD.Level,
        CurrentLocation: cL,
        Role: resourceD.Role,
        Started: resourceD.Location,
        Low: RRd.Low,
        Good: RRd.Good,
        TargetStatus: tStatus
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    loadData()
    setFRole("")
    setFLocation("")
    setFLevel("")
    setFTarget("")
  }

  return (
    <PaperSheet title='Manage Breakdowns' divider rightSideContent={<AddButton  onClick={handleOpen} title='Add New Breakdown'/>}>
      {!loading && hasPermissions([Permissions.SUPER_CONFIGURATION]) && 
         <Grid container spacing={2}>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="filter-panel-content"
                id="filter-panel-header"
              >
                <Typography className={classes.heading}>Actions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Level</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterLevel}
                        onChange={(e) => doFilter((e.target.value as string), 'Level')}
                      >
                        {levels?.map(d => (
                          <MenuItem key={levels?.indexOf(d)} value={d.Name}>
                            {d.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterRole}
                        onChange={(e) => doFilter((e.target.value as string), 'Role')}
                      >
                        {roles?.map(d => (
                          <MenuItem key={roles?.indexOf(d)} value={d.Name}>
                            {d.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Location</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterLocation}
                        onChange={(e) => doFilter((e.target.value as string), 'Location')}
                      >
                        {locations?.map(d => (
                          <MenuItem key={levels?.indexOf(d)} value={d.Name}>
                            {d.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">On Target?</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterTarget}
                        onChange={(e) => doFilter((e.target.value as string), 'Target')}
                      >
                        {tStatusses?.map(d => (
                          <MenuItem key={levels?.indexOf(d)} value={d.Name}>
                            {d.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <CustomButton title='Clear Filter' onClick={clear}/>
                  <div style={{marginLeft: 10}}>
                    <CustomButton title='Export' onClick={createExcel}/>
                  </div>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
         <BreakdownTable 
            breakdowns={bData}
            names={names} 
            projects={projects}
            loadData={loadData}
            locations={locations}/>
         </Grid>
         </Grid>}
      <SimpleDialog open={open} handleClose={handleClose} title='Manage Breakdown'>
        <BreakdownForm 
          submitted={submitted} 
          submitting={submitting} 
          isDisabled={isDisabled} 
          onSubmit={onSubmit} 
          names={names} 
          projects={projects}
          locations={locations}/>
      </SimpleDialog>
      {error && <Snackbar message={error}/>}
    </PaperSheet>
  )
}

export function Filter(data: Breakdown[], filter: Filter) {
  var array : Breakdown[] = data
  var filtered: Breakdown[] = []
  let propertyToUtilise: keyof Breakdown = filter.attribute as keyof Breakdown
  for (let i = 0; i < array.length; i++) {
      if (array[i][propertyToUtilise] === filter.value) {
          filtered.push(array[i]);
      }
  }
  return filtered
}