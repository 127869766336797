import React from 'react'
import { Form } from 'formik'
import FormikTextField from '../common/FormikTextField'
import SubmitButton from '../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from '../common/CustomFormik'
import SmartSelectorField from '../common/SmartSelect'
import moment from 'moment'
import FormikDatePicker from '../common/FormikDatePicker'
import SmartMultiSelectorField from '../common/MultiSelector'
import { NumberCheck } from '../utils/ValidationFunctions'

interface Props {
  onSubmit: (values: BreakdownFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: BreakdownFields
  isDisabled: boolean
  names: Types[]
  projects: itemType[]
  locations: Attribute[]
}

export interface BreakdownFields {
  Name: string
  Current: number | null
  Projects: string[]
  StatusDate: number | null
  Notes: string
  CurrentLocation: string

}

export default class BreakdownForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          Name: '',
          Current: null,
          Projects: [],
          StatusDate: moment().startOf('day').unix(),
          Notes: '',
          CurrentLocation: ''
        } as BreakdownFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={4} style={{padding: 20}}>
              <SmartSelectorField name='Name' label='Name' data={this.props.names} xs={6} required/>
              <FormikTextField name='Current' label='Current' type='number' variant='outlined' id='lowfield' required xs={6} inputProps={ { step: 1, min: 0, max: 100, maxLength: 3 } } validate={NumberCheck}/>
              <SmartSelectorField name='CurrentLocation' label='Site' attributes={this.props.locations} attributeField xs={6}/>
              <SmartMultiSelectorField name='Projects' label='Projects' items={this.props.projects} placeholder='Choose' required/>
              <FormikDatePicker name='StatusDate' label='Status Date' />
              <FormikTextField name='Notes' label='Notes' variant='outlined' id='namefield' xs={8} multiline rows={10} inputProps={ { maxLength: 500 } }/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}