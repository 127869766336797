import React from 'react'
import PaperSheet from '../components/common/Paper'
import BuildIcon from '@material-ui/icons/Build'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PersonIcon from '@material-ui/icons/Person'
import TimelineIcon from '@material-ui/icons/Timeline'
import DescriptionIcon from '@material-ui/icons/Description'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import Grid from '@material-ui/core/Grid'
import Cardify from '../components/common/Cardify'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import CustomJoyride from '../components/common/CustomJoyride'
import ListIcon from '@material-ui/icons/List'
import ListAltIcon from '@material-ui/icons/ListAlt'

const styles = (theme: Theme) => createStyles({
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing() * 2
  }
})

interface Props {
}

class Home extends React.Component {
  render () {
    return (
      <PaperSheet helmetTitle='Home'>
        <CustomJoyride
          continuous
          steps={[
            {
              target: '#paperSheet',
              content: `Welcome to auxilium. This is likely a fresh look on what you're used to.`
            },
            {
              target: '#reportLink',
              content: `The most important feature will likely be here! Clicking this button will take you to a form that can be filled out to report a bug.`
            },
            {
              target: '#myReports',
              content: `Clicking this button will allow you to view all the reports you have submitted at different statuses, you can also view details of the report as well as an audit log`
            }
          ]}
        />
        <Grid container spacing={10} alignItems='flex-end'>
          {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Cardify to='/breakdowns' id='breakdownLink' label='Breakdown' iconLabel={TimelineIcon} background='#2874D0' />}
          {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Cardify to='/resources' id='resourcesLink' label='Resources' iconLabel={PersonIcon} background='#9C1EB4' />}
          {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Cardify to='projects' id='projectsLink' label='Projects' iconLabel={AssignmentIcon} background='#4B27B0' />}
          {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Cardify to='/RR' id='RRLink' label='Roles and Recoverabilty' iconLabel={BusinessCenterIcon} background='#D02863' />}
          {hasPermissions([Permissions.SUPER_CONFIGURATION]) && <Cardify to='/attributes' id='attributesLink' label='Attributes' iconLabel={BuildIcon} background='#D02863' />}
        </Grid>
      </PaperSheet>
    )
  }
}
export default withStyles(styles, { withTheme: true })(Home)
