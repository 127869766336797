import React from 'react'
import { Form } from 'formik'
import FormikTextField from '../common/FormikTextField'
import SubmitButton from '../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from '../common/CustomFormik'
import SmartSelectorField from '../common/SmartSelect'
import FormikDatePicker from '../common/FormikDatePicker'

interface Props {
  onSubmit: (values: ResourceFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: ResourceFields
  isDisabled: boolean
  levels: Attribute[]
  roles: Attribute[]
  locations: Attribute[]
  types: Attribute[]
  managers: Attribute[]
}

export interface ResourceFields {
  Name: string
  Level: string
  OrgStructure: string
  Role: string
  Location: string
  Started: number | null
  Manager: string
  Type: string
}

export default class ResourceForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          Name: '',
          Level: '',
          OrgStructure: '',
          Role: '',
          Location: '',
          Started: null,
          Manager: '',
          Type: ''
        } as ResourceFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={4} style={{padding: 20}}>
              <FormikTextField name='Name' label='Name' variant='outlined' id='namefield' required xs={4} inputProps={ { maxLength: 50 } }/>
              <SmartSelectorField name='Level' label='Level' attributes={this.props.levels} attributeField xs={4} required/>
              <SmartSelectorField name='OrgStructure' label='Org Structure' attributes={this.props.roles} attributeField xs={4} required/>
              <SmartSelectorField name='Role' label='Role' attributes={this.props.roles} attributeField xs={4} required/>
              <SmartSelectorField name='Location' label='Location' attributes={this.props.locations} attributeField xs={4} required/>
              <FormikDatePicker name='Started' label='Started' />
              <SmartSelectorField name='Manager' label='Manager' attributes={this.props.managers} attributeField xs={4} required/>
              <SmartSelectorField name='Type' label='Type' attributes={this.props.types} attributeField xs={4} required/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
