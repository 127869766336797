import React, { CSSProperties, HTMLAttributes } from 'react'
import Select, {Props as SelectProps} from 'react-select'
import { createStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import classnames from 'classnames'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/styles'
import { NoticeProps, MenuProps } from 'react-select/src/components/Menu'
import { ControlProps } from 'react-select/src/components/Control'
import { OptionProps } from 'react-select/src/components/Option'
import { PlaceholderProps } from 'react-select/src/components/Placeholder'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { MultiValueProps } from 'react-select/src/components/MultiValue'
import { ValueType } from 'react-select/src/types'
import { Field, FieldConfig, FieldProps } from 'formik'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 290
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto'
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden'
    },
    formControl: {
      width: '100%'
    },
    chip: {
      margin: theme.spacing(0.5, 0.25)
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08
      )
    },
    singleValue: {
      fontSize: 16
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0
    },
    divider: {
      height: theme.spacing(2)
    }
  })
)

function NoOptionsMessage(props: NoticeProps<ItemType>) {
  return (
    <Typography color='textSecondary' className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />
}

function Control(props: ControlProps<ItemType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, textFieldProps }
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...textFieldProps}
    />
  )
}

function Option(props: OptionProps<ItemType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

type MuiPlaceholderProps = Omit<PlaceholderProps<ItemType>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<ItemType>, 'innerProps'>>
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography color='textSecondary' className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

function SingleValue(props: SingleValueProps<ItemType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer(props: ValueContainerProps<ItemType>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function MultiValue(props: MultiValueProps<ItemType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classnames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props: MenuProps<ItemType>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

export interface ItemType {
  value: string
  label: string
}

interface Props extends SelectProps {
  items: ItemType[]
  label: string
  placeholder: string
  isDisabled?: boolean
}

export default class SmartMultiSelectorField extends React.Component<Props & FieldConfig> {
  render () {
    return (
      <Field {...this.props} component={stylishSelect} />
    )
  }
}

const MultiSelector: React.FunctionComponent<Props & FieldProps> = props => {
  const theme = useTheme()
  const classes = useStyles()
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  }

  const onChange = (selectedObjects: ValueType<ItemType>) => {
    if (!selectedObjects) return props.form.setFieldValue(props.field.name,[])
    if (Array.isArray(selectedObjects)) return props.form.setFieldValue(props.field.name, selectedObjects.map((object: ItemType) => object.value))
  }

  var { field, form, ...additionalProps } = props

  return (
    <Grid item xs={6}>
      <FormControl className={classes.formControl}>
        <Select
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label: props.label,
            InputLabelProps: {
              shrink: true
            }
          }}
          options={props.items}
          isDisabled={props.isDisabled}
          components={components}
          onChange={onChange}
          name={props.field.name}
          value={props.items.filter(item => props.field.value.includes(item.value))}
          placeholder={props.placeholder}
          isMulti
        />
      </FormControl>
    </Grid>
  )
}

const stylishSelect = MultiSelector
