import React from 'react'
import PaperSheet from '../components/common/Paper'
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import ProjectTable from '../components/tables/ProjectTable'
import ProjectForm, { ProjectFields } from '../components/forms/ProjectForm'
import AddButton from '../components/common/buttons/AddButton'
import SimpleDialog from '../components/common/SimpleDialog'
import Snackbar from '../components/common/Snackbar'
import { hasPermissions } from '../components/common/permissionControl'
import Permissions from '../permissionsList'
import { AttributeFilter } from '../components/utils/AttributeFilter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    }
  })
)


export default function ProjectConfig() {
  const classes = useStyles();
  const [project, setProject] = React.useState<Project[]>([]);
  const [statuses, setStatuses] = React.useState<Attribute[]>([])
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [error, setError] = React.useState<string| null>(null)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitting(false)
    setSubmitted(false)
  }
  const loadData = async() => {
    var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/projects', {method: 'GET'})
    var project = await response.json()
    setProject(project.projects.Items)
  }

  React.useEffect(() => {
    setLoading(true)
    async function getData() {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/projects', {method: 'GET'})
      var project = await response.json()
      setProject(project.projects.Items)
      var statusResponse = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/attributes', {method: 'GET'})
      var stati = await statusResponse.json()
      stati = AttributeFilter(stati.attributes.Items,'Project Status')
      setStatuses(stati)
    }
    getData()
    setLoading(false)
  }, [])

  const onSubmit = async (values: ProjectFields) => {
    setSubmitting(true)
    try {
      var response = await fetch('https://a9eh5kzg05.execute-api.eu-west-2.amazonaws.com/main/projects/submit', {
      body: JSON.stringify({
        ...values
      }), method: 'POST'
    })
    setSubmitted(true)
    setSubmitting(false)
    handleClose()
    loadData()
    } catch (error) {
      setSubmitted(false)
      setSubmitting(false)
    }
  }

  return (
    <PaperSheet title='Manage Projects' divider rightSideContent={<AddButton  onClick={handleOpen} title='Add New Project'/>}>
      {!loading && hasPermissions([Permissions.SUPER_CONFIGURATION]) && <ProjectTable projects={project} statuses={statuses} loadData={loadData}/>}
      <SimpleDialog open={open} handleClose={handleClose} title='Manage Project'>
        <ProjectForm submitted={submitted} submitting={submitting} isDisabled={isDisabled} onSubmit={onSubmit} statuses={statuses}/>
      </SimpleDialog>
      {error && <Snackbar message={error}/>}
    </PaperSheet>
  )
}