const types: Types[] = [
  {Name: 'Location'},
  {Name: 'Role'},
  {Name: 'Level'},
  {Name: 'Target Status'},
  {Name: 'Resource Type'},
  {Name: 'Project Status'},
  {Name: 'Manager'},
]

export {
  types
}