import React from 'react'
import { Form } from 'formik'
import FormikTextField from '../common/FormikTextField'
import SubmitButton from '../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from '../common/CustomFormik'
import SmartSelectorField from '../common/SmartSelect'
import { NumberCheck } from '../utils/ValidationFunctions'

interface Props {
  onSubmit: (values: RecoverabilityFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: RecoverabilityFields
  isDisabled: boolean
  roles: Attribute[]
  levels: Attribute[]
}

export interface RecoverabilityFields {
  Role: string
  Level: string
  Low: number | null
  Good: number | null
}

export default class RecoverabilityForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          Role: '',
          Level: '',
          Low: null,
          Good: null
        } as RecoverabilityFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={4} style={{padding: 20}}>
              <SmartSelectorField name='Role' label='Role' attributes={this.props.roles} attributeField xs={6} required/>
              <SmartSelectorField name='Level' label='Level' attributes={this.props.levels} attributeField xs={6} required/>
              <FormikTextField name='Low' label='Low' type='number' variant='outlined' id='lowfield' required xs={6} inputProps={ { step: 1, min: 0, max: 100, maxLength: 3 } } validate={NumberCheck}/>
              <FormikTextField name='Good' label='Good' type='number' variant='outlined' id='goodfield' required xs={6} inputProps={ { step: 1, min: 0, max: 100, maxLength: 3 } } validate={NumberCheck}/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
