import React from 'react'
import Joyride, { Props as JoyrideProps } from 'react-joyride'
import { SessionContextProps, withSessionContext } from '../../SessionContext'

interface Props extends JoyrideProps, SessionContextProps {
  disableAutoStart?: boolean
}

/**
 * Imagine if react-joyride hooked into the 'helpActive' of ConfigContext. Well this is that reality, baby!
 */
class CustomJoyride extends React.Component<Props> {
  render () {
    var { sessionContext, disableAutoStart, callback, ...otherProps } = this.props
    if (!sessionContext.helpActive) return null

    if (!sessionContext.helpActive || otherProps.steps.length === 0) return null

    if (!disableAutoStart) otherProps.steps[0].disableBeacon = true

    // 1600 is higher than anything in material-ui.
    return (
      <Joyride
      callback={props => { callback && callback(props); props.lifecycle === 'init' && props.action === 'reset' && sessionContext.toggleHelp() }}
      scrollOffset={this.props.scrollOffset || 100}
      styles={{ options: { zIndex: 1600 } }}
      {...otherProps} />
    )
  }
}

export default withSessionContext(CustomJoyride)
