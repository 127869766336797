import React from 'react'
import { Form } from 'formik'
import FormikTextField from '../common/FormikTextField'
import SubmitButton from '../common/buttons/SubmitButton'
import { Grid } from '@material-ui/core'
import CustomFormik from '../common/CustomFormik'
import SmartSelectorField from '../common/SmartSelect'

interface Props {
  onSubmit: (values: ProjectFields) => void
  submitted: boolean,
  submitting: boolean,
  values?: ProjectFields
  isDisabled: boolean
  statuses: Attribute[]
}

export interface ProjectFields {
  Name: string
  Status: string
}

export default class ProjectForm extends React.Component<Props> {
  render () {
    return (
      <CustomFormik
        submitted={this.props.submitted}
        disableUnsavedPrompt={false}
        isDisabled={this.props.isDisabled}
        initialValues={this.props.values || {
          Name: '',
          Status: ''
        } as ProjectFields}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false) // I'm not using Formik's submitting tracking. It doesn't have a concept of submitted, so both states should be tracked by the parent.
          this.props.onSubmit(values)
        }}
        render={(formProps: any) => (
          <Form>
            <Grid container spacing={4} style={{padding: 20}}>
              <FormikTextField name='Name' label='Name' variant='outlined' id='namefield' required xs={6} inputProps={ { maxLength: 50 } }/>
              <SmartSelectorField name='Status' label='Status' attributes={this.props.statuses} attributeField xs={6} required/>
              <Grid item xs={2}><SubmitButton onClick={formProps.submitForm} submitting={this.props.submitting} submitted={this.props.submitted} /></Grid>
            </Grid>
          </Form>
        )}
      />
    )
  }
}
