import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import './App.css'
import Router from './components/Router'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Authenticator from './components/Authenticator'
import SessionProvider from './components/utils/SessionProvider'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
Amplify.configure(awsconfig)

/**
 * This is the entrypoint for the application. It doesn't do a lot.
 */

interface State {
  user: any
  authState: any
  userDetails: any
  darkMode: boolean
}
 export default function App() {
  const [user, setUser] = React.useState<any>({})
  const [authState, setAuthState] = React.useState<any>({})

  React.useEffect(() => {
    return onAuthUIStateChange(async (nextAuthState, authData) => {
      if (!authData) return
      setUser(authData)
      setAuthState(nextAuthState)
      console.log(user)
    })
  })


    return authState === AuthState.SignedIn && user ? (
      <MuiPickersUtilsProvider utils={MomentUtils}>
          <SessionProvider user={user}>
            <Router/>
            <CssBaseline />
          </SessionProvider>
        </MuiPickersUtilsProvider>
    ) : (<Authenticator />)
}