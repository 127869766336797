import React from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import NavBar from './common/NavBar'
import Sidebar from './common/Sidebar'
import Footer from './common/Footer'
import HomePage from '../routes/HomePage';
import { Backdrop } from '@material-ui/core';
import { SessionContextProps, withSessionContext } from '../SessionContext'
import { AuthState} from '@aws-amplify/ui-components';
import AttributesConfig from '../routes/AttributesConfig'
import ProjectConfig from '../routes/ProjectsConfig'
import ResourceConfig from '../routes/ResourceConfig'
import RecoverabilityConfig from '../routes/RecoverabilityConfig'
import BreakdownConfig from '../routes/BreakdownConfig'

interface Props extends SessionContextProps {
}

class Router extends React.Component<Props>{

    
    render() {
        return(
            <HashRouter>
                <React.Fragment>
                {AuthState.SignedIn && <PageDecorations/>}
                    <ErrorBoundary>
                        <Switch>
                            <Route path='/' exact component={HomePage}/>
                            <Route path='/attributes' exact component={AttributesConfig}/>
                            <Route path='/projects' exact component={ProjectConfig} />
                            <Route path='/resources' exact component={ResourceConfig} />
                            <Route path='/RR' exact component={RecoverabilityConfig} />
                            <Route path='/breakdowns' exact component={BreakdownConfig} />
                        </Switch>
                    </ErrorBoundary>
                </React.Fragment>
            </HashRouter>
        )
    }
}

export default withSessionContext(Router)

class PageDecorations extends React.Component<{}, { open: boolean }> {
    state = {
      open: false
    }
    handleDrawerClose = () => {
      this.setState({ open: false })
    }
  
    handleDrawerOpen = () => {
      this.setState({ open: true })
    }
  
    render () {
      return (
        <React.Fragment>
          <NavBar open={this.state.open} handleDrawerOpen={this.handleDrawerOpen}/>
          <Backdrop style={{zIndex: 2}} open={this.state.open} onClick={this.handleDrawerClose}/>
          <Sidebar open={this.state.open} handleDrawerClose={this.handleDrawerClose} />
          <Footer open={this.state.open} />
        </React.Fragment>
      )
    }
  }
  
