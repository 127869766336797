import React from 'react'

/**
 * Refer to App.js for where these are properly implemented.
 */
var context = React.createContext<SessionContext | null>(null)
export default context

/**
 * A higher order component (HOC). Any component exported through this function will
 * receive the sessionContext as a prop. Handy, eh?
 * The manic Pick prop things are the result of help from StackOverflow: https://stackoverflow.com/a/50613946.
 */
export function withSessionContext<P extends SessionContextProps> (Component: React.ComponentType<P>) {
  var wrapper = function (props: Pick<P, Exclude<keyof P, keyof SessionContextProps>>) {
    return (
      <context.Consumer>
        {(sessionContext) => {
          if (sessionContext === null) return <h1>sessionContext was null! (are you below a provider?)</h1>
          return <Component sessionContext={sessionContext} {...props as P} />
        }}
      </context.Consumer>
    )
  }
  Object.defineProperty(wrapper, 'name', { value: `withSessionContext(${Component.name})` })
  return wrapper
}

export const LocalTokenKey = 'localToken'
export const DarkModeKey = 'darkMode'

declare global {
  interface Window {
    sessionContext: SessionContext
  }
}

export interface SessionContext {
  token: TokenResponse | null
  changeTokenObject: (newToken: TokenResponse) => void
  logout: (noRevoke?: boolean) => void
  isLoggedIn: boolean
  expired: boolean
  userRole: string
  helpActive: boolean
  toggleHelp: () => void
  darkMode: boolean
  toggleDarkMode: () => void
}

export interface SessionContextProps {
  sessionContext: SessionContext
}
