import React from 'react'
export function AttributeFilter (data: Attribute[], filter: string) {
  var filtered: Attribute[] = []
  for (var i = 0; i <data.length; i++) {
    var currentData = data[i]
    if(currentData.Type === filter) {
      filtered.push(currentData)
    }
  }
  return filtered
}